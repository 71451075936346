<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      incomeExpenditure: [],
      incomeData: [],
      expensesData: [],
      total: 0,
      totalExpenese: 0,
      totalIncome: 0,
      levelThreeIncome: [],
      levelThreeExpense: [],
      branch_id: null,
      data: {},
    };
  },
  methods: {
    getIncomeExpenditure() {
      this.http
        .post("transactions/income-expense", {
          branch_id: this.branch_id,
          data: this.data,
        })
        .then((res) => {
          this.incomeExpenditure = res.data;
          this.incomeExpenditure.forEach((exp) => {
            if (exp.id == 1) {
              return exp.level_one_chart_of_accounts.forEach((level_one) => {
                return level_one.level_two_chart_of_accounts.forEach(
                  (level_two, index) => {
                    this.levelThreeIncome.push({ l2_name: level_two.name });
                    this.levelThreeIncome[index].level_three = [];
                    this.levelThreeIncome[index].total = 0;
                    return (
                      level_two.level_three_chart_of_accounts.forEach(
                        (level_three, child) => {
                          this.levelThreeIncome[index].level_three.push({
                            name: level_three.name,
                          });
                          this.levelThreeIncome[index].level_three[
                            child
                          ].total = 0;
                          return level_three.transaction_details.forEach(
                            (transaction) => {
                              this.levelThreeIncome[index].level_three[
                                child
                              ].total += parseFloat(transaction.value);
                              this.levelThreeIncome[index].total += parseFloat(
                                transaction.value
                              );
                              return (this.total += parseFloat(
                                transaction.value
                              ));
                            }
                          );
                        }
                      ),
                      this.incomeData.push({
                        name: level_two.name,
                        Total: this.total,
                      }),
                      (this.total = 0)
                    );
                  }
                );
              });
            }
            if (exp.id == 3) {
              return exp.level_one_chart_of_accounts.forEach((level_one) => {
                return level_one.level_two_chart_of_accounts.forEach(
                  (level_two, index) => {
                    this.levelThreeExpense.push({ l2_name: level_two.name });
                    this.levelThreeExpense[index].level_three = [];
                    this.levelThreeExpense[index].total = 0;
                    return (
                      level_two.level_three_chart_of_accounts.forEach(
                        (level_three, child) => {
                          this.levelThreeExpense[index].level_three.push({
                            name: level_three.name,
                          });
                          this.levelThreeExpense[index].level_three[
                            child
                          ].total = 0;
                          return level_three.transaction_details.forEach(
                            (transaction) => {
                              this.levelThreeExpense[index].level_three[
                                child
                              ].total += parseFloat(transaction.value);
                              this.levelThreeExpense[index].total += parseFloat(
                                transaction.value
                              );
                              return (this.total += parseFloat(
                                transaction.value
                              ));
                            }
                          );
                        }
                      ),
                      this.expensesData.push({
                        name: level_two.name,
                        Total: this.total,
                      }),
                      (this.total = 0)
                    );
                  }
                );
              });
            }
          });
          console.log(this.incomeData);
          this.totalIncome = this.incomeData.reduce((a, b) => {
            return a + b.Total;
          }, 0);

          this.totalExpenese = this.expensesData.reduce((a, b) => {
            return a + b.Total;
          }, 0);
          console.log(this.levelThreeIncome);
        });
    },
    searchByDate() {
      this.incomeExpenditure = [];
      this.incomeData = [];
      this.expensesData = [];
      this.total = 0;
      this.totalExpenese = 0;
      this.totalIncome = 0;
      this.levelThreeIncome = [];
      this.levelThreeExpense = [];
      this.getIncomeExpenditure();
    },
  },
  created() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
    // this.getIncomeExpenditure();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('menu.menuitems.accounting.subItems.income-expenditure')"
    />
    <form @submit.prevent="searchByDate()">
      <div class="row">
        <div class="col">
          <label class="inline" for="fromJobTitle">{{
            $t("report.from")
          }}</label>
          <input
            v-model="data.from"
            type="date"
            class="form-control"
            id="fromJobTitle"
            required
          />
        </div>
        <div class="col">
          <label class="inline" for="toJobTitle">{{ $t("report.to") }}</label>
          <input
            v-model="data.to"
            type="date"
            class="form-control fromto"
            id="toJobTitle"
            required
          />
        </div>
        <div class="col-auto" style="top: 16px">
          <button type="submit" class="btn btn-primary">
            {{ $t("popups.search") }}
          </button>
        </div>
        <div class="col"></div>
      </div>
    </form>
    <table
      style="margin: 0 !important"
      class="table table-bordered"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <thead></thead>
      <tbody>
        <tr class="text-center">
          <td></td>
          <td class="text-bold" v-if="totalExpenese != 0">
            Total Expense:
            {{ totalExpenese.toLocaleString() }}
          </td>
          <td></td>
          <td class="text-bold" v-if="totalIncome != 0">
            Total Income: {{ totalIncome.toLocaleString() }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row g-0">
      <div class="col-6">
        <table
          class="table table-bordered"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <thead>
            <tr
              class="text-light text-center table_border"
              style="background-color: #2a3042 !important"
            >
              <td>Expenditure</td>
              <td>To</td>
              <td>Value</td>
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="expense in levelThreeExpense"
              :key="expense"
            >
              <td class="text-center">{{ expense.l2_name }}</td>
              <td class="text-center">
                <tr v-for="l3 in expense.level_three" :key="l3">
                  <td class="col text-center">
                    {{ l3.name }}
                  </td>
                </tr>
              </td>
              <td class="text-center">
                <tr
                  class="text-center"
                  v-for="l3 in expense.level_three"
                  :key="l3"
                >
                  <td class="col text-center">
                    {{ l3?.total?.toLocaleString() }}
                  </td>
                </tr>
              </td>
              <td>
                {{ expense?.total?.toLocaleString() }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <table
          class="table table-bordered"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <thead>
            <tr
              class="text-light text-center table_border"
              style="background-color: #2a3042 !important"
            >
              <td>Income</td>
              <td>From</td>
              <td>value</td>
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="income in levelThreeIncome"
              :key="income"
            >
              <td class="text-center">{{ income.l2_name }}</td>
              <td class="text-center">
                <tr v-for="l3 in income.level_three" :key="l3">
                  <td class="col text-center">
                    {{ l3.name }}
                  </td>
                </tr>
              </td>
              <td class="text-center">
                <tr
                  class="text-center"
                  v-for="l3 in income.level_three"
                  :key="l3"
                >
                  <td class="col text-center">
                    {{ l3?.total?.toLocaleString() }}
                  </td>
                </tr>
              </td>
              <td>
                {{ income?.total?.toLocaleString() }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Layout>
</template>
<style scoped></style>
